import md5 from 'blueimp-md5';
import { defineStore } from 'pinia';
import { nextTick, reactive, readonly } from 'vue';
import { sleep } from '../utils';

const VERTICAL_GAP_PX = 8; //px

export const useSnackbarStore = defineStore('SnackbarStore', () => {
  const snackbars = reactive({});
  const pwa = reactive({
    promptEvent: null,
    isInstalled: false,
    isOpened: false,
    installable: false,
    isMobileDevice: false,
    isTabletDevice: false,
  });

  window.isMobileDeviceCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  window.isTabletDeviceCheck = function () {
    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    const hasNoMouseMove = typeof window.onmousemove !== 'function';
    const isMacIntel = navigator.platform === 'MacIntel';

    // Check if the device has touch support, no mousemove event, and is reported as "MacIntel" platform
    return isTouchDevice && hasNoMouseMove && isMacIntel;
  };

  function setupPwa() {
    window.addEventListener('beforeinstallprompt', (e) => {
      pwa.promptEvent = e;
    });
    window.addEventListener('appinstalled', () => {
      // Executed in PWA right after installation is done (PC)
      pwa.isInstalled = true;
    });
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // Executed when PWA is opened
      pwa.isOpened = true;
    }
    if ('serviceWorker' in navigator) {
      // When PWA is installable (PC doesn't look at your browser)
      pwa.installable = true;
    }
    pwa.isMobileDevice = window.isMobileDeviceCheck();
    pwa.isTabletDevice = window.isTabletDeviceCheck();
  }

  function getPwa() {
    return pwa;
  }

  async function show(message, { id, timeout, vbind, actions } = {}) {
    id ??= md5(message);
    if (snackbars[id]) {
      await close(id);
    }

    const defaultActions = [
      {
        text: 'Close',
        click: (id) => close(id),
      },
    ];

    snackbars[id] = {
      message,
      timeout: timeout ?? 3000,
      vbind,
      isOpen: true,
      actions: (actions ?? defaultActions).map((button) => ({
        ...button,
        click: button.click ? () => button.click(id) : undefined,
      })),
    };

    await nextTick();
    fixSnackbarsVerticalAlignment();

    return id;
  }

  async function close(...partialIds) {
    const validIds = [];
    partialIds.forEach((partialId) => {
      let snackbar = snackbars[partialId];
      if (!snackbar) {
        partialId = Object.keys(snackbars).find((key) => key.includes(partialId));
        snackbar = snackbars[partialId];
      }

      if (!snackbar) {
        return;
      }

      validIds.push(partialId);
      snackbar.isOpen = false;
    });

    // fix alignment on isOpen update, once it reset the elements' alignment
    await nextTick();
    fixSnackbarsVerticalAlignment();

    // wait close animation to remove from reactive variable
    await sleep(0.2);
    validIds.forEach((id) => {
      delete snackbars[id];
    });
    await nextTick();
    fixSnackbarsVerticalAlignment();
  }

  function fixSnackbarsVerticalAlignment() {
    const snackbarElements = [
      ...document.querySelectorAll('.global-snackbar .v-snackbar__wrapper'),
    ];
    snackbarElements.reduce((totalTop, snackbarElement, index) => {
      snackbarElement.style.bottom = `${totalTop + VERTICAL_GAP_PX * index}px`;

      const height = snackbarElement.scrollHeight;
      return totalTop + height;
    }, 0);
  }

  return {
    snackbars: readonly(snackbars),
    pwa: readonly(pwa),
    show,
    close,
    setupPwa,
    getPwa,
  };
});
